import React from 'react';
import CardMedia from '@mui/material/CardMedia';

// Assuming your image is in the public folder and named 'banner.jpg'
const zelzahGardenImage = '/images/zelzah_garden.jpeg';

function Banner() {
    return (
        <CardMedia
            component="img"
            height="280"
            image={zelzahGardenImage}
            alt="HOA Community Banner"
        />
    );
}

export default Banner;
