import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Banner from "./Banner";

function App() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                p: 3,
            }}
        >
            <Banner />
            <Typography variant="h4" component="h1" gutterBottom>
                Welcome to Zelzah Garden HOA Community
            </Typography>
            <Typography variant="body1">
                Stay up-to-date with the latest announcements and updates. Check back often for new information.
            </Typography>
        </Box>
    );
}

export default App;
